import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  Component,
  Inject,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Route, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../shared.module';

import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuItem, MessageService } from 'primeng/api';
import { Contact, VCard } from '../vcard.types';
import { vCard } from '../constants/vcard.constant';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import { StepperModule, StepperPanel } from 'primeng/stepper';

import { CarouselModule } from 'primeng/carousel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    RouterOutlet,
    SharedModule,
    SplitButtonModule,
    StepperModule,
    InputTextModule,
    CarouselModule,
    InputTextareaModule,
    ButtonModule,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [ApiService],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  mediaUrl: string = environment.mediaUrl;
  langItems: MenuItem[] = [
    {
      label: 'fr',
      command: () => {
        this.updateLang(0);
      },
    },
    {
      label: 'en',
      command: () => {
        this.updateLang(1);
      },
    },
    {
      label: 'ar',
      command: () => {
        this.updateLang(2);
      },
    },
  ];
  selectedLangItem = this.langItems[0];

  vCard!: VCard;
  HashKey!: string | null;
  dispaly: boolean = true;
  showInfo: boolean = false;
  contactForm!: FormGroup;
  contactFormSubmitted: boolean = false;
  loading: boolean = false;
  contact!: Contact;
  responsiveOptions: any[] = [
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 3,
      numScroll: 1,
    },
  ];

  get fullname() {
    return this.contactForm.controls['fullname'];
  }
  get phone() {
    return this.contactForm.controls['phone'];
  }
  get email() {
    return this.contactForm.controls['email'];
  }
  get subject() {
    return this.contactForm.controls['subject'];
  }
  get message() {
    return this.contactForm.controls['message'];
  }

  /**
   * Constructor
   * @param translate
   * @param _apiService
   * @param router
   * @param _activatedRoute
   * @param titleService
   * @param messageService
   */
  constructor(
    private translate: TranslateService,
    private _apiService: ApiService,
    @Inject(Router) private router: Router,
    private _activatedRoute: ActivatedRoute,
    private titleService: Title,
    private messageService: MessageService
  ) {
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');

    this._activatedRoute.paramMap.subscribe((params) => {
      this.HashKey = params.get('HashKey');
      if (this.HashKey) {
        this.getProfile(this.HashKey);
      } else {
        console.warn('HashKey not found in route parameters.');
      }
    });
  }

  /**
   * ***********************************************************
   * Life cycle
   * ***********************************************************
   */

  ngOnInit(): void {
    this._activatedRoute.fragment.subscribe((value) => {
      this.jumpto(value);
    });
  }

  ngAfterViewInit() {
    this.scrollToFragment();
  }

  /**
   * ***********************************************************
   * Public methods
   * ***********************************************************
   */

  /**
   * Get business card details by key
   * @param HashKey
   */
  getProfile(HashKey: string) {
    this._apiService.get('/BusinessCards/profile/' + HashKey).subscribe({
      next: (vCard: VCard) => {
        this.vCard = vCard;
        this.titleService.setTitle(
          'Business Card - ' + this.vCard.firstName + ' ' + this.vCard.lastName
        );
        this.showInfo = true;

        this.contactForm = new FormGroup({
          fullname: new FormControl('', [Validators.required]),
          phone: new FormControl('', [
            Validators.required,
            Validators.pattern(
              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
            ),
          ]),
          email: new FormControl('', [Validators.required, Validators.email]),
          subject: new FormControl('', [Validators.required]),
          message: new FormControl('', [Validators.required]),
          BusinessCardId: new FormControl(this.vCard?.id),
        });
      },
      // 404
      error: (error: any) => {
        this.router.navigate(['error'], { relativeTo: this._activatedRoute });
      },
    });
  }

  /**
   * Download VCF File
   * @param id
   * @param HashKey
   */
  downloadVcf() {
    this.loading = true;
    this._apiService
      .downloadVcf(`${this.vCard.id}/` + this.vCard.hashKey)
      .subscribe({
        next: (vCard: any) => {
          this.showInfo = true;
          const url = window.URL.createObjectURL(vCard);
          const xy = document.createElement('a');
          xy.href = url;
          xy.download = `${
            'Business Card - ' +
            this.vCard.firstName +
            ' ' +
            this.vCard.lastName
          }.vcf`;
          xy.click();
          window.URL.revokeObjectURL(url);
          this.loading = false;
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
        },
      });
  }

  /**
   * Submit contact form
   */
  submitContact() {
    this.contactFormSubmitted = true;
    
    if (this.contactForm.valid) {
      this._apiService.createContact(this.contactForm.value).subscribe({
        next: (rest: any) => {
          this.contactForm.reset();
          this.contactFormSubmitted = false;
          this.messageService.add({
            severity: 'Sucess',
            summary: 'success',
            detail: 'The message has been sent',
          });
        },
        error: (err: any) => {
          console.log('no valid data', err);
          this.contactFormSubmitted = false;
        },
      });
    }
  }

  /**
   * On lang change
   * @param index
   */
  updateLang(index: number) {
    this.selectedLangItem = this.langItems[index];
    this.translate.use(this.selectedLangItem.label as string);
  }

  scrollToFragment() {
    this._activatedRoute.fragment.subscribe((fragment) => {
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  jumpto(section: any) {
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
  }
}
