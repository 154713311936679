import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FindByPropertyPipe } from './pipes/find-by-property.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

// Fonction pour configurer le TranslateLoader
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    FindByPropertyPipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      
    }),
    ReactiveFormsModule,
    ToastModule
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FindByPropertyPipe,
    SafeHtmlPipe,
    ReactiveFormsModule,
    MessageModule,
    ToastModule
  ],
  providers:[
    MessageService,
    ToastModule
  ]
})
export class SharedModule { }
